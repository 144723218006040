export const CONTRACT_NAMES = [
  // V1
  'cw-govmod-single',
  'cw-proposal-single',
  // V2
  'cwd-proposal-single',
  'dao-proposal-single',
]

export const PRE_PROPOSE_CONTRACT_NAMES = ['dao-pre-propose-single']
