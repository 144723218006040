import { CreateDaoGovernance } from './CreateDaoGovernance'
import { CreateDaoReview } from './CreateDaoReview'
import { CreateDaoStart } from './CreateDaoStart'
import { CreateDaoVoting } from './CreateDaoVoting'

export const CreateDaoPages = [
  CreateDaoStart,
  CreateDaoGovernance,
  CreateDaoVoting,
  CreateDaoReview,
]
